import { BaseTheme, Body, Hbox, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { ActivityIndicator } from '@web/atomic/legacy/atm.activity-indicator';
import * as React from 'react';
import { useTheme } from 'styled-components';
import { MobilePlaceholderCardContainer, MobilePlaceholderEmojiTextStyled } from './mobile-placeholder.component.style';

interface MobilePlaceholderProps {
  emoji: string;
  description: string | React.ReactNode;
}

/**
 * A placeholder which looks the same as the mobile one
 */
export const MobilePlaceholder: React.FunctionComponent<MobilePlaceholderProps> = (props) => {
  const theme = useTheme() as BaseTheme;
  if (theme.name === 'regular') {
    return (
      <CardWrapper>
        <MobilePlaceholderContent {...props} />
      </CardWrapper>
    );
  } else {
    return (
      <MobilePlaceholderCardContainer padding>
        <MobilePlaceholderContent {...props} />
      </MobilePlaceholderCardContainer>
    );
  }
};

export const MobilePlaceholderContent: React.FunctionComponent<MobilePlaceholderProps> = (props) => {
  const theme = useTheme() as BaseTheme;
  return (
    <>
      <Separator />
      <Separator />
      <Hbox hAlign="center">
        <EmojiText emoji={props.emoji} />
      </Hbox>
      <Separator />
      <Body kind={theme.name === 'dark' ? 'light' : 'default'} role="tooltip" data-microtip-position="top" center>
        {props.description}
      </Body>
      <Separator />
      {props.children}
    </>
  );
};

interface EmojiTextProps {
  emoji: string;
}

const EmojiText: React.FunctionComponent<EmojiTextProps> = (props) => {
  return <MobilePlaceholderEmojiTextStyled>{props.emoji}</MobilePlaceholderEmojiTextStyled>;
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export const ShimmerMobilePlaceholder: React.FunctionComponent = () => {
  const theme = useTheme() as BaseTheme;
  if (theme.name === 'regular') {
    return (
      <CardWrapper>
        <Body role="tooltip" data-microtip-position="top" center>
          <ActivityIndicator light={0} />
        </Body>
      </CardWrapper>
    );
  } else {
    return (
      <MobilePlaceholderCardContainer padding>
        <Body kind="light" role="tooltip" data-microtip-position="top" center>
          <ActivityIndicator />
        </Body>
      </MobilePlaceholderCardContainer>
    );
  }
};
