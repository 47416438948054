import {
  GetAppointmentsInfosRequest,
  GetAppointmentsInfosResponse,
  GetAppointmentsInfoUrl,
} from '@global/utils/api/appointment.controller.model';
import { shuffleWithWeightsAndFallback } from '@global/utils/array/shuffle';
import { swapArrayElements } from '@global/utils/array/swap';
import { createEmailUrl } from '@global/utils/url/create-email-url';
import { createWebProfileUrl } from '@global/utils/url/create-profile-urls';
import { getWhatsappClickToChat } from '@global/utils/url/create-whatsapp-url';
import { teleheathPaths } from '@global/utils/url/paths';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { EmailContacts } from '@lp-root/src/domain/contacts';
import { preUserTelehealthNavigate } from '@lp-root/src/modules/telehealth/pre-user/pre-user-navigation';
import {
  HcpInfo,
  HcpInicioProfileQueryOutput,
  mapToHcpCardInfoInput,
  PreUserHcpProfilePageParams,
} from '@lp-root/src/modules/telehealth/profile/telehealth.utils';
import { PreUserAgendarPageParams } from '@lp-root/src/modules/telehealth/schedule-page/schedule-page.utils';
import { HcpCardInfo, StartPageWrapper } from '@lp-root/src/modules/telehealth/start-page/start-page-wrapper.component';
import { PageProps } from '@lp-root/src/utils/local-types';
import { Separator } from '@web/atomic';
import { AlertWithButtonsWrapper } from '@web/atomic/legacy/obj.custom-alert-with-buttons-wrapper/custom-alert-with-buttons-wrapper.component';
import AlertWithButtonsDispatcherService, {
  AlertButton,
} from '@web/atomic/legacy/obj.custom-alert-with-buttons-wrapper/custom-alert.service';
import { useGetUsingPreUserAuth } from '@web/data/use-get-with-pre-user-auth';
import { addDays, startOfDay } from 'date-fns';
import { graphql } from 'gatsby';
import * as React from 'react';

/** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
const PreUserInicioPage: React.FunctionComponent<PageProps<unknown, HcpInicioProfileQueryOutput>> = (props) => {
  const { data, error, loading } = useGetUsingPreUserAuth<GetAppointmentsInfosRequest, GetAppointmentsInfosResponse>({
    url: GetAppointmentsInfoUrl,
  });

  React.useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  const { health_carer_telehealth } = props.data?.hasura;

  const handleGetMoreAppointmentsClick = () => {
    const appointmentCount = data?.appointment_count;
    const message = `Olá, realizei ${appointmentCount} consulta${
      appointmentCount > 1 ? 's' : ''
    }. Gostaria de agendar mais uma. Minha identificação é ${data?.user_id}. Poderia me ajudar?`;
    AlertWithButtonsDispatcherService.dispatchMessage(
      'Para saber mais você pode conversar com nossa equipe. Como você prefere fazer isso?',
      buildButtonsForMessage(message)
    );
  };

  const handleMoreInfoAboutNextAppointmentClick = () => {
    const message = `Olá. Minha identificação é ${data?.user_id}. Tenho uma consulta agendada e gostaria de ajuda com `;
    AlertWithButtonsDispatcherService.dispatchMessage(
      'Entre em contato com nossa equipe para esclarecer dúvidas ou resolver questões. Como você prefere fazer isso?',
      buildButtonsForMessage(message)
    );
  };

  const handleMoreInfoAboutSchedulingClick = () => {
    const message = `Olá. Minha identificação é ${data?.user_id}. Gostaria de saber mais informações sobre as teleconsultas.`;
    AlertWithButtonsDispatcherService.dispatchMessage(
      'Entre em contato com nossa equipe para esclarecer suas dúvidas. Como você prefere fazer isso?',
      buildButtonsForMessage(message)
    );
  };

  const getNextAvailableAppointmentDate = () => {
    const lastAppointmentDateString = data.last_appointment?.start;
    return lastAppointmentDateString && data.min_days_between_appointments
      ? startOfDay(addDays(new Date(lastAppointmentDateString), data.min_days_between_appointments))
      : new Date();
  };

  /** SMELL_ALERT: this same operation is performed in the mobile side, therefore if something changes here, make sure to also update it there. @see nextAvailableAppointmentDate at packages/mobile.patient/src/app/modules/webview/webview-for-telehealth.page.tsx */

  const handleScheduleClick = (hcpId: string, hcpName: string) => {
    preUserTelehealthNavigate<PreUserAgendarPageParams, never>({
      path: teleheathPaths.preUserSchedule.path,
      queryParam: {
        preUserId: data.user_id,
        hcpId,
        hcpName,
        nextAvailableAppointmentDate: getNextAvailableAppointmentDate(),
        davId: data.dav_id,
      },
    });
  };

  const handleProfileClick = (hcpId: string, hcpName: string) => {
    preUserTelehealthNavigate<PreUserHcpProfilePageParams, never>({
      path: createWebProfileUrl(hcpName, hcpId),
      queryParam: {
        preUserId: data.user_id,
        nextAvailableAppointmentDate: getNextAvailableAppointmentDate(),
        davId: data.dav_id,
      },
    });
  };

  const shuffleHcpList = () => {
    const randomOrderedHcpList = shuffleWithWeightsAndFallback<HcpInfo>(
      health_carer_telehealth,
      health_carer_telehealth.map((hcp) => hcp.sort_weight)
    );
    const randomOrderedHcpCardList = mapToHcpCardInfoInput(randomOrderedHcpList);
    const firstHcpId = data?.last_appointment?.health_carer_id;
    const firstHcpIndex = randomOrderedHcpCardList.findIndex((hcp) => hcp.healthCarerId === firstHcpId);
    if (firstHcpIndex > 0) {
      return swapArrayElements<HcpCardInfo>(randomOrderedHcpCardList, 0, firstHcpIndex);
    }
    return randomOrderedHcpCardList;
  };

  return (
    <IndexLayout {...props}>
      <StartPageWrapper
        hcp={shuffleHcpList()}
        appointmentInfo={data}
        getAppointmentError={error}
        getAppointmentLoading={loading}
        onGetMoreAppointmentsClick={handleGetMoreAppointmentsClick}
        onMoreInfoAboutNextAppointmentClick={handleMoreInfoAboutNextAppointmentClick}
        onMoreInfoAboutSchedulingClick={handleMoreInfoAboutSchedulingClick}
        onScheduleClick={handleScheduleClick}
        onViewProfileClick={handleProfileClick}
      />
      <Separator />
      <AlertWithButtonsWrapper />
    </IndexLayout>
  );
};

export default PreUserInicioPage;

function buildButtonsForMessage(message: string): AlertButton[] {
  return [
    { title: `Conversar por whatsapp`, kind: 'primary', onClick: () => window.open(getWhatsappClickToChat('support', message)) },
    {
      title: `Enviar e-mail (${EmailContacts.Support})`,
      kind: 'primary',
      onClick: () => window.open(createEmailUrl(EmailContacts.Support, 'Ajuda para agendar mais consultas', message)),
    },
  ];
}

export const query = graphql`
  query HcpInfoQuery {
    hasura {
      health_carer_telehealth(where: { active: { _eq: true } }) {
        dav_id
        approaches
        education
        profile_url
        themes
        description
        sort_weight
        health_carer {
          id
          name
          professional_id
          professional_id_state
        }
      }
    }
  }
`;
