import React from 'react';
import { InlineListItem, InlineListWrapper } from './inline-list.component.style';

export interface InlineListProps {
  elements: string[];
}

export const InlineList: React.FunctionComponent<InlineListProps> = (props) => {
  if (!props.elements?.length) {
    return null;
  }
  return (
    <InlineListWrapper>
      {props.elements.map((element, index) => (
        <InlineListItem key={index}>{element} </InlineListItem>
      ))}
    </InlineListWrapper>
  );
};
