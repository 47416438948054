import { GrayColor, Spacing } from '@web/atomic';
import styled from 'styled-components';
import { CardContainer, CardContainerProps } from '../atm.card/card.style';

export const MobilePlaceholderCardContainer = styled(CardContainer)<CardContainerProps>`
  padding: ${Spacing.Large};
  background: ${(props) => (props.color ? props.color : GrayColor.GrayXXDark)};
`;

export const MobilePlaceholderEmojiTextStyled = styled.span`
  font-size: 40px;
`;
