import { Spacing } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CardTopBackground = require('@lp-root/src/assets/images/card-top-background.svg').default;

export const PersonCardTemplateStyled = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${Spacing.Medium});
  background-image: ${({ theme }) => (theme.name === 'regular' ? `url(${CardTopBackground})` : 'none')};
  background-size: 100% 114px;
  background-repeat: no-repeat;
  background-position: top;
`;

export const PersonCardTemplateImgSize = 136;
export const PersonCardTemplateImgStyled = styled.div`
  height: ${PersonCardTemplateImgSize}px;
  width: ${PersonCardTemplateImgSize}px;
  margin: ${Spacing.XXLarge} auto ${Spacing.Large};
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 126px;
`;
