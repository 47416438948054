import { Recurrence_Model_Enum_Enum } from '@global/utils/remote-graphql-types';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { MobilePlaceholder } from '@lp-root/src/components/mol.mobile-placeholder/mobile-placeholder.component';
import { StartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import {
  PersonCardTemplate,
  PersonCardTemplateProps,
} from '@lp-root/src/components/obj.person-card-template/person-card-template.component';
import { AutoMargin, Body, Col, Grid, Row, Separator } from '@web/atomic';
import { InlineList } from '@web/atomic/atm.inline-list/inline-list.component';
import { scrollToId } from '@web/utils/scroll';
import React from 'react';
import { AdvertisementSection, IAdvertisementSectionProps } from '../components/advertisement-section.component';

interface StartPageHcpInfo extends PersonCardTemplateProps {
  description: string;
  professionId: string;
  tags: string[];
  healthCarerId: string;
}

interface StartPageProps {
  appointmentCount: number;
  onScheduleClick: (hcpId: string) => void;
  onMoreInfoClick: () => void;
  onViewProfileClick: (hcpId: string) => void;
  hcp: StartPageHcpInfo[];
  advertisement?: IAdvertisementSectionProps;
  /**
   * TODO B2C: THIS OPTIONAL PARAMS CAN BE MANDATORY AFTER MOBILE B2C RELEASE
   */
  subscriptionExpiration?: string;
  recurenceModel?: Recurrence_Model_Enum_Enum;
  appointmentCarriedOutCount?: number;
  maxAppointment?: number;
}

const StartSectionId = 'start-section';

export const StartPage: React.FunctionComponent<StartPageProps> = (props) => {
  const hcpList = props.hcp;
  return (
    <Grid>
      <Row>
        <Col lg={4} md={5} sm={12} xs={12}>
          <StartSection noMarginTop title={'Agendamento'} />
          <ScheduleAppointmentPlaceholder {...props} />
          <Separator />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div id={StartSectionId} />
          <StartSection title={props.hcp?.length > 1 ? 'Escolha quem te atenderá' : 'Quem te atenderá'} />
        </Col>
      </Row>
      <Row>
        {hcpList.map((hcp) => (
          <Col key={hcp.healthCarerId} lg={4} md={5} sm={12} xs={12}>
            <React.Fragment>
              <PersonCardTemplate {...hcp}>
                <Body>{hcp.description}</Body>
                <InlineList elements={hcp.tags} />
                <AutoMargin />
                <GatsbyButton expanded onClick={() => props.onScheduleClick(hcp.healthCarerId)}>
                  Fazer agendamento
                </GatsbyButton>
                <Separator />
                <GatsbyButton kind="secondary" expanded onClick={() => props.onViewProfileClick(hcp.healthCarerId)}>
                  Ver perfil
                </GatsbyButton>
              </PersonCardTemplate>
              <Separator />
            </React.Fragment>
          </Col>
        ))}
      </Row>
      {props.advertisement && (
        <Row>
          <Col xs={12}>
            <AdvertisementSection {...props.advertisement} />
          </Col>
        </Row>
      )}
    </Grid>
  );
};

const ScheduleAppointmentPlaceholder: React.FunctionComponent<StartPageProps> = (props) => {
  return (
    <MobilePlaceholder emoji="🧑‍⚕️" description={getStartDescription(props)}>
      <GatsbyButton
        expanded
        onClick={() => {
          if (props.hcp?.length === 1) {
            props.onScheduleClick(props.hcp[0].healthCarerId);
          } else {
            scrollToId(StartSectionId, 0);
          }
        }}
      >
        Começar agendamento
      </GatsbyButton>
      <Separator />
      <GatsbyButton expanded outlined kind="primary" onClick={props.onMoreInfoClick}>
        Saiba mais
      </GatsbyButton>
    </MobilePlaceholder>
  );
};

const getStartDescription = (input: StartPageProps): string | React.ReactNode => {
  if (!input?.appointmentCount) {
    return 'Realize seu primeiro atendimento para obter resultados ainda melhores com o programa. ';
  }

  /**
   * TODO B2C: THIS IF CAN BE REMOVED AFTER MOBILE B2C RELEASE
   */
  if (!input?.maxAppointment) {
    input.appointmentCount
      ? 'Você não possui consultas agendadas. Que tal realizar o agendamento da próxima?'
      : 'Realize seu primeiro atendimento para obter resultados ainda melhores com o programa. ';
  }
  const appointmentTodo = input?.maxAppointment - input?.appointmentCarriedOutCount;

  if (input.recurenceModel === Recurrence_Model_Enum_Enum.None) {
    return (
      <>
        Você não possui consultas agendadas. <strong>Que tal realizar o agendamento da próxima?</strong> Você ainda tem {appointmentTodo}{' '}
        consulta{appointmentTodo === 1 ? '' : 's'}.
      </>
    );
  }

  return (
    <>
      Você não possui consul tas agendadas. <strong>Que tal realizar o agendamento da próxima?</strong> Você tem {appointmentTodo} consulta
      {appointmentTodo === 1 ? '' : 's'} para fazer até o dia {input.subscriptionExpiration}.
    </>
  );
};
