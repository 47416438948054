// https://developer.mozilla.org/en-US/docs/Glossary/Base64

/**
 * atob(): decodes a Base64-encoded string ("atob" should be read as "ASCII to binary").
 */
export const fromBase64: (str: string) => string = atob;

/**
 * btoa(): creates a Base64-encoded ASCII string from a "string" of binary data ("btoa" should be read as "binary to ASCII").
 */
export const toBase64: (str: string) => string = btoa;
