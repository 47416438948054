import { Separator } from '@web/atomic/legacy/obj.box/box.component.style';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { StartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import React from 'react';

export interface IAdvertisementSectionProps {
  title: string;
  description: string;
  callToAction: string;
  onClick: () => void;
}

export const AdvertisementSection: React.FunctionComponent<IAdvertisementSectionProps> = (props) => {
  return (
    <>
      {props.title && <StartSection title={props.title} description={props.description} />}
      {props.callToAction && props.onClick && (
        <GatsbyButton expanded onClick={props.onClick}>
          {props.callToAction}
        </GatsbyButton>
      )}
      <Separator />
    </>
  );
};
