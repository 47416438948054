type Path = Record<string, { name: string; path: string }>;

export const teleheathPaths: Path = {
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  telehealthProfile: { name: 'Perfil', path: '/teleatendimento/' },
  preUserStart: { name: 'Teleconsultas', path: '/pre-user/teleatendimento/inicio/' },
  preUserSchedule: { name: 'Agendamento de consultas', path: '/pre-user/teleatendimento/agendar/' },
  preUserHcpProfile: { name: 'Agendamento de consultas', path: '/pre-user/teleatendimento/' },
  preUserRegister: { name: 'Agendamento', path: '/pre-user/teleatendimento/registrar/' },
};

export const chatfuelPaths: Path = {
  sleepDiaryList: { name: 'Histórico do sono', path: '/chatfuel/lista-de-diario' },
  diaryLog: { name: 'Evolução do sono', path: '/chatfuel/ver-diario' },
  techniquesAndTagsComparator: { name: 'Comparador de técnicas e fatores', path: `/chatfuel/comparador-de-tecnicas-e-fatores` },
  diaryConfiguration: { name: 'Configurações', path: `/chatfuel/configuracao-diario` },
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  telehealthStart: { name: 'Início', path: `/chatfuel/teleatendimento/inicio-v2` },
  scheduleAppointment: { name: 'Agendamento', path: `/chatfuel/teleatendimento/agendar` },
  davRegister: { name: 'Agendamento', path: `/chatfuel/teleatendimento/registrar/` },
};
