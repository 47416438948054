import { Body, Col, Grid, H2, Row, Separator } from '@web/atomic';
import { Modal } from '@web/atomic/legacy/obj.modal';
import * as React from 'react';
import { Button } from '../../atm.button/button.component';
import AlertDispatcherService, { AlertWithButtonsDispatcherListener, AlertWithButtonsMessageData } from './custom-alert.service';

interface AlertWithButtonsWrapperState {
  alertMessage: AlertWithButtonsMessageData;
}

const AlertDispatcher = AlertDispatcherService;
/**
 * It is a wrapper that shows Custom Alert on the window.
 */
export class AlertWithButtonsWrapper
  extends React.Component<unknown, AlertWithButtonsWrapperState>
  implements AlertWithButtonsDispatcherListener
{
  constructor(props: unknown) {
    super(props);
    this.state = {
      alertMessage: null,
    };
  }

  componentDidMount(): void {
    AlertDispatcher.setListener(this);
  }

  componentWillUnmount(): void {
    AlertDispatcher.setListener(null);
  }

  render(): JSX.Element {
    const alertMessage = this.state.alertMessage;
    if (!alertMessage) return null;
    return (
      <Modal background="themed" opened small onClose={this.handleMessageClose} disableScrollLock={true}>
        <Grid fluid>
          <Row mb mt>
            <Col xs={11}>
              <Body>{alertMessage.message}</Body>
            </Col>
          </Row>
          {alertMessage.buttons.map((button, index) => {
            return (
              <React.Fragment key={index}>
                <Button type="button" kind={button.kind} expanded onClick={button.onClick}>
                  {button.title}
                </Button>
                <Separator />
              </React.Fragment>
            );
          })}
          {alertMessage.buttons?.length == 0 && <Separator />}
        </Grid>
      </Modal>
    );
  }

  handleMessageClose = () => {
    this.setState({ alertMessage: null });
  };

  // AlertDispatcherListener
  onReceiveMessage = (data: AlertWithButtonsMessageData): void => {
    this.setState({ alertMessage: data });
  };
}
