import { ButtonKind } from '../../atm.button/button.component';

export interface AlertButton {
  title: string;
  kind?: ButtonKind;
  onClick: () => void;
}

export interface AlertWithButtonsMessageData {
  message: string;
  buttons: Array<AlertButton>;
}

export interface AlertWithButtonsDispatcherListener {
  onReceiveMessage: (data: AlertWithButtonsMessageData) => void;
}

/**
 * This class is used to dispatch a custom alert modal to notify its listener that something
 * wants to show a alert modal.
 */
class AlertWithButtonsDispatcherService {
  private listener: AlertWithButtonsDispatcherListener;

  setListener(listener: AlertWithButtonsDispatcherListener) {
    this.listener = listener;
  }

  dispatchMessage(message: string, buttons: AlertButton[]) {
    if (this.listener) {
      this.listener.onReceiveMessage({ message, buttons });
    }
  }
}

export default new AlertWithButtonsDispatcherService();
