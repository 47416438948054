import { hasDocument, hasWindow } from '@web/utils/platform';
import QueryString from 'querystringify';

export interface PreUserAuthDataSourceQueryParams {
  /** pre-user token */
  t: string;
}

class PreUserAuthDataSource {
  private token: string;

  setToken = (token: string) => {
    this.token = token;
  };

  getToken = () => {
    if (this.token) return this.token;

    if (!hasDocument()) return null;

    const search: Partial<PreUserAuthDataSourceQueryParams> = QueryString.parse(hasWindow() ? window.location.search : '');
    this.token = search.t;
    return this.token;
  };
}

export default new PreUserAuthDataSource();
