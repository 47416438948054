import PreUserAuthDataSource from './pre-user-auth.datasource';
import { HttpAuthHeader } from './vigilantes.datasource';

enum PreUserAuthHeadersError {
  InvalidCredentialsError = 'Invalid PreUser Credentials',
}

export class PreUserAuthHeaders implements HttpAuthHeader {
  getAuthHeader = async (): Promise<{ 'x-pre-user-token': string }> => {
    const token = PreUserAuthDataSource.getToken();
    if (!token) {
      throw new Error(PreUserAuthHeadersError.InvalidCredentialsError);
    }
    return {
      'x-pre-user-token': token || '',
    };
  };
}
