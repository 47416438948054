import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { MobilePlaceholder, ShimmerMobilePlaceholder } from '@lp-root/src/components/mol.mobile-placeholder/mobile-placeholder.component';
import { Col, Grid, Row, Separator } from '@web/atomic';
import React, { useEffect, useState } from 'react';
import { IAdvertisementSectionProps } from '../components/advertisement-section.component';

import { GetAppointmentsInfosResponse } from '@global/utils/api/appointment.controller.model';
import { parseDateToDDMMYYYY } from '@global/utils/date';
import { ShimmeStartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import { TelehealthMaxAppointmentCountReachedPlaceholder } from '@lp-root/src/components/org.telehealth/telehealth-reached-appointment.component';
import { NextAppointmentPage } from '@lp-root/src/modules/telehealth/next-appointment-page/next-appointment-page.component';
import { StartPage } from '@lp-root/src/modules/telehealth/start-page';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { hasWindow } from '@web/utils/platform';

export interface HcpCardInfo {
  description: string;
  healthCarerId: string;
  professionId: string;
  imgSrc: string;
  name: string;
  tags: string[];
}
[];

interface IStartPageWrapperProps {
  appointmentInfo: GetAppointmentsInfosResponse;
  getAppointmentError: GetAppointmentsInfosResponse;
  getAppointmentLoading: boolean;
  advertisement?: IAdvertisementSectionProps;
  hcp: HcpCardInfo[];
  onScheduleClick: (hcpId: string, hcpName: string) => void;
  onGetMoreAppointmentsClick: () => void;
  onMoreInfoAboutNextAppointmentClick: () => void;
  onMoreInfoAboutSchedulingClick: () => void;
  onViewProfileClick: (hcpId: string, hcpName: string, hasDavId: boolean) => void;
}

/**
 * It wraps the StartPage component and decorates with more functionalities:
 * - loading placeholder
 * - error placeholder
 * - a placeholder when the all available appointments are scheduled
 * - show the next appointment page
 *
 * @param props
 * @returns
 */
export const StartPageWrapper: React.FunctionComponent<IStartPageWrapperProps> = (props) => {
  const { appointmentInfo: data, getAppointmentError: error, getAppointmentLoading: loading, advertisement } = props;
  const [showMaxAppointmentCountReached, setShowMaxAppointmentCountReached] = useState<boolean>(false);
  useEffect(() => {
    const appointmentCount = data?.appointment_perform_count - data?.appointment_scheduled;
    setShowMaxAppointmentCountReached(data?.max_appointment && appointmentCount && data?.max_appointment === appointmentCount);
  }, [data]);

  const nextAppointment = data?.next_appointment[0];
  const recurrenceModel = data?.recurrence_model;
  const maxAppointments = data?.max_appointment;
  const appointmentsCarriedOutOnRecurrencePeriod = data?.appointment_perform_count - data?.appointment_scheduled;
  const subscriptionExpiration = parseDateToDDMMYYYY(new Date(data?.subscription_expiration));

  if (showMaxAppointmentCountReached) {
    return (
      <Grid>
        <Row center="xs">
          <Col xs={12} sm={12} md={5} lg={4}>
            <Separator />
            <TelehealthMaxAppointmentCountReachedPlaceholder
              recurrenceModel={recurrenceModel}
              appointmentsCarriedOutOnRecurrencePeriod={appointmentsCarriedOutOnRecurrencePeriod}
              maxAppointments={maxAppointments}
              subscriptionExpiration={subscriptionExpiration}
              onGetMoreAppointmentsClick={props.onGetMoreAppointmentsClick}
            />
          </Col>
        </Row>
      </Grid>
    );
  }

  return (
    <>
      <LoadingState loading={loading} error={!!error} data={!!data}>
        <LoadingState.Shimmer>
          <Grid>
            <Row mb>
              <Col lg={4} md={5} sm={9} xs={12}>
                <ShimmeStartSection noMarginTop />
                <ShimmerMobilePlaceholder />
              </Col>
            </Row>
          </Grid>
        </LoadingState.Shimmer>
        <LoadingState.Error>
          <Grid>
            <Row center="xs">
              <Col xs={12} sm={12} md={5} lg={4}>
                <Separator />
                <MobilePlaceholder emoji="🧐" description="Erro ao pegar seus dados. Clique no botão abaixo para tentar novamente.">
                  <GatsbyButton
                    expanded
                    onClick={() => {
                      if (hasWindow()) window.location.reload();
                    }}
                  >
                    Recarregar página
                  </GatsbyButton>
                </MobilePlaceholder>
              </Col>
            </Row>
          </Grid>
        </LoadingState.Error>

        {nextAppointment ? (
          <NextAppointmentPage
            url={nextAppointment?.url}
            date={nextAppointment?.date}
            hcp={props.hcp.find((h) => h.healthCarerId === nextAppointment.health_carer_id)}
            onMoreInfoClick={props.onMoreInfoAboutNextAppointmentClick}
            advertisement={advertisement}
            onViewProfileClick={(hcpId: string) => {
              const hcpName = props.hcp.find((h) => h.healthCarerId === hcpId).name;
              props.onViewProfileClick(hcpId, hcpName, !!data?.dav_id);
            }}
          />
        ) : (
          <StartPage
            subscriptionExpiration={subscriptionExpiration}
            recurenceModel={recurrenceModel}
            appointmentCarriedOutCount={appointmentsCarriedOutOnRecurrencePeriod}
            maxAppointment={maxAppointments}
            appointmentCount={data?.appointment_count}
            onScheduleClick={(hcpId: string) => {
              const hcpName = getHcpName(props.hcp, hcpId);
              props.onScheduleClick(hcpId, hcpName);
            }}
            onViewProfileClick={(hcpId: string) => {
              const hcpName = getHcpName(props.hcp, hcpId);
              props.onViewProfileClick(hcpId, hcpName, !!data?.dav_id);
            }}
            onMoreInfoClick={props.onMoreInfoAboutSchedulingClick}
            hcp={props.hcp}
            advertisement={advertisement}
          />
        )}
      </LoadingState>
    </>
  );
};

const getHcpName = (hcp: HcpCardInfo[], hcpId: string) => {
  return hcp.find((h) => h.healthCarerId === hcpId).name;
};
