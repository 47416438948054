import { useRef } from 'react';
import { JsonObject } from 'type-fest';
import { PreUserAuthHeaders } from './vigilantes.datasource.pre-user-auth';
import { useGet, UseGetInput, UseGetResponse } from './use-get.hook';
import { VigilantesDataSource } from './vigilantes.datasource';

/**
 * @see PreUserAuthHandler (packages/app.api/src/api/middleware/authentication.middleware.ts) to undeerstand how this Auth works
 */
export function useGetUsingPreUserAuth<Request = JsonObject, Response = JsonObject>(
  input: UseGetInput<Request, Response>
): UseGetResponse<Response> {
  const datasource = useRef(new VigilantesDataSource(new PreUserAuthHeaders()));
  return useGet<Request, Response>(input, datasource.current);
}
