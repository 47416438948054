import { Site } from '@lp-root/src/data/graphql/local-graphql-types';
import { fromBase64 } from '@lp-root/src/utils/base-64';
import { hasWindow } from '@web/utils/platform';
import QueryString from 'querystringify';
import { HcpCardInfo } from '../start-page/start-page-wrapper.component';

export interface PreUserHcpProfilePageQueryParams {
  /** state: base-64 encoded PreUserHCPProfilePageParams */
  p: string;
}

export interface PreUserHcpProfilePageParams {
  preUserId: string;
  token: string;
  nextAvailableAppointmentDate: Date;
  davId: string;
}

interface ProfileData {
  education?: Array<string>;
  approaches?: Array<string>;
  themes?: Array<string>;
  profile_url?: string;
  full_description?: string;
  health_carer?: {
    id: string;
    professional_id: string;
    professional_id_state: string;
    name: string;
    sort_weight: number;
  };
}

export interface HcpInfo {
  themes?: Array<string>;
  description?: string;
  profile_url?: string;
  sort_weight: number;
  health_carer: {
    id: string;
    professional_id: string;
    name: string;
  };
}

export interface HcpInicioProfileQueryOutput {
  hasura: {
    health_carer_telehealth: HcpInfo[];
  };
}

export interface HcpProfileTemplateQueryOutput {
  hasura: {
    health_carer_telehealth: ProfileData[];
  };
  site: Site;
}

export const getPreUserHcpProfilePageParams = (): PreUserHcpProfilePageParams => {
  const params: PreUserHcpProfilePageQueryParams = QueryString.parse(hasWindow() ? window.location.search : '');
  const state: PreUserHcpProfilePageParams = JSON.parse(fromBase64(params.p));
  state.nextAvailableAppointmentDate = new Date(state.nextAvailableAppointmentDate);
  return state;
};

export const mapToHcpCardInfoInput = (data: HcpInfo[]): HcpCardInfo[] => {
  return data.map((hcp) => {
    return {
      description: hcp.description,
      healthCarerId: hcp.health_carer.id,
      professionId: hcp.health_carer.professional_id,
      imgSrc: hcp.profile_url,
      name: hcp.health_carer.name,
      tags: hcp.themes,
    };
  });
};
