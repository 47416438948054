import { parseHour } from '@global/utils/date/parse-hour';
import { parseDateToLongString } from '@global/utils/date/parse-to-string';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { StartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import {
  PersonCardTemplate,
  PersonCardTemplateProps,
} from '@lp-root/src/components/obj.person-card-template/person-card-template.component';
import { Body, Col, Grid, H3, Row, SemiBold, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import React from 'react';
import { AdvertisementSection, IAdvertisementSectionProps } from '../components/advertisement-section.component';

export interface NextAppointmentPageHcpInfo extends PersonCardTemplateProps {
  description: string;
  healthCarerId: string;
}

interface NextAppointmentPageProps {
  onMoreInfoClick: () => void;
  hcp: NextAppointmentPageHcpInfo;
  date: string;
  url: string;
  advertisement?: IAdvertisementSectionProps;
  onViewProfileClick: (hcpId: string) => void;
}

export const NextAppointmentPage: React.FunctionComponent<NextAppointmentPageProps> = (props) => {
  return (
    <Grid>
      <Row>
        <Col lg={4} md={5} sm={9} xs={12}>
          <StartSection noMarginTop title={'Próxima consulta'} />
          <NextAppointment {...props} />
          <Separator />
        </Col>
        {props.hcp && (
          <Col lg={4} md={5} sm={9} xs={12}>
            <StartSection noMarginTop title={'Quem te atenderá'} />
            <Row>
              <PersonCardTemplate {...props.hcp}>
                <>
                  <Body>{props.hcp.description}</Body>
                  <Separator />
                  <GatsbyButton kind="secondary" expanded onClick={() => props.onViewProfileClick(props.hcp.healthCarerId)}>
                    Ver perfil
                  </GatsbyButton>
                </>
              </PersonCardTemplate>

              <Separator />
            </Row>
          </Col>
        )}
      </Row>
      {props.advertisement && (
        <Row>
          <Col xs={12}>
            <AdvertisementSection {...props.advertisement} />
          </Col>
        </Row>
      )}
    </Grid>
  );
};

const NextAppointment: React.FunctionComponent<NextAppointmentPageProps> = (props) => {
  const buttonText = 'Entrar na sala';
  const appointmentDate = new Date(props.date);
  return (
    <CardWrapper>
      <H3>
        {parseDateToLongString(appointmentDate)}, {parseHour(appointmentDate.toString())}
      </H3>
      <Body>
        Sua consulta com <SemiBold>{props.hcp.name}</SemiBold> está agendada. No horário marcado, clique no botão “{buttonText}”.
      </Body>
      <Separator />
      <GatsbyButton expanded to={props.url} external>
        {buttonText}
      </GatsbyButton>
      <Separator />
      <GatsbyButton expanded outlined onClick={props.onMoreInfoClick}>
        Suporte
      </GatsbyButton>
    </CardWrapper>
  );
};
