import { Recurrence_Model_Enum_Enum } from '@global/utils/remote-graphql-types';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { MobilePlaceholder } from '@lp-root/src/components/mol.mobile-placeholder/mobile-placeholder.component';
import * as React from 'react';

interface ITelehealthMaxAppointmentCountReachedPlaceholderProps {
  recurrenceModel: Recurrence_Model_Enum_Enum;
  appointmentsCarriedOutOnRecurrencePeriod: number;
  maxAppointments: number;
  subscriptionExpiration: string;
  onGetMoreAppointmentsClick: () => void;
}

export const TelehealthMaxAppointmentCountReachedPlaceholder: React.FunctionComponent<
  ITelehealthMaxAppointmentCountReachedPlaceholderProps
> = (props) => {
  return (
    <MobilePlaceholder
      emoji="😐"
      description={getShowMaxAppointmentCountReachedDescription(
        props.recurrenceModel,
        props.appointmentsCarriedOutOnRecurrencePeriod,
        props.subscriptionExpiration
      )}
    >
      <GatsbyButton expanded onClick={props.onGetMoreAppointmentsClick}>
        Obter mais consultas
      </GatsbyButton>
    </MobilePlaceholder>
  );
};

const getShowMaxAppointmentCountReachedDescription = (
  recurrenceModel: Recurrence_Model_Enum_Enum,
  appointmentsDoneOnRecurrencePeriod: number,
  subscriptionExpiration: string
) => {
  let description = `Não é possível realizar mais agendamentos.`;
  const isMoreThanOneAppointment = appointmentsDoneOnRecurrencePeriod > 1;
  if (recurrenceModel !== Recurrence_Model_Enum_Enum.None && recurrenceModel) {
    description =
      description +
      `${isMoreThanOneAppointment ? `Você realizou ${appointmentsDoneOnRecurrencePeriod} consultas,` : ''}${
        isMoreThanOneAppointment ? 'mas elas estarão disponíveis' : ' Uma nova consulta estará disponível'
      } novamente em ${subscriptionExpiration}.`;
  }
  return description;
};
