import { Body, H3 } from '@web/atomic';
import * as React from 'react';
import { PersonCardTemplateImgStyled, PersonCardTemplateStyled, ProfileImage } from './person-card-template.component.style';

export interface PersonCardTemplateProps {
  imgSrc: string;
  name: string;
  professionId: string;
}

export const PersonCardTemplate: React.FunctionComponent<PersonCardTemplateProps> = (props) => {
  return (
    <PersonCardTemplateStyled>
      <PersonCardTemplateImgStyled>
        <ProfileImage src={props.imgSrc} alt={`Foto de ${props.name}`} />
      </PersonCardTemplateImgStyled>
      <H3 center cell>
        {props.name}
      </H3>
      <Body center>{props.professionId}</Body>
      {props.children}
    </PersonCardTemplateStyled>
  );
};
