import { Border, Spacing } from '@web/atomic';
import styled from 'styled-components';

export interface CardContainerProps {
  minHeight?: number;
  padding?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: ${Border.Radius};
  min-height: ${(props) => (props.minHeight >= 0 ? `${props.minHeight}px` : '250px')};
  padding-bottom: ${(props) => (props.padding ? '0px' : Spacing.Large)};
  padding-left: ${(props) => (props.padding ? Spacing.Medium : '0px')};
  padding-right: ${(props) => (props.padding ? Spacing.Medium : '0px')};
  padding-top: ${(props) => (props.padding ? Spacing.Large : '0px')};
`;
