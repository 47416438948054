export const createEmailUrl = (email: string, subject?: string, body?: string) => {
  const appendSubject = subject ? `subject=${encodeURIComponent(subject)}` : '';
  const appendBody = body ? `body=${encodeURIComponent(body)}` : '';
  const appends = [appendSubject, appendBody].reduce(
    (prev, curr, index) => (index === 0 ? prev.concat(curr) : prev.concat('&', curr)),
    '?'
  );

  return `mailto:${email}${appends}`;
};
