import { diactriclessKebabCase } from '../string/case';
import { teleheathPaths } from './paths';

const createPathProfileUrls = (hcpName: string, hcpId: string) => {
  return `${diactriclessKebabCase(hcpName)}-${hcpId}`;
};

export const createNativeProfileUrl = (hcpName: string, hcpId: string): string => {
  return `${teleheathPaths.telehealthProfile.path}${createPathProfileUrls(hcpName, hcpId)}`;
};

export const createWebProfileUrl = (hcpName: string, hcpId: string): string => {
  return `${teleheathPaths.preUserHcpProfile.path}${createPathProfileUrls(hcpName, hcpId)}`;
};
