import { teleheathPaths } from '@global/utils/url/paths';
import { toBase64 } from '@lp-root/src/utils/base-64';
import PreUserAuthDataSource from '@web/data/pre-user-auth.datasource';
import { navigate } from 'gatsby';
import QueryString from 'querystringify';

export interface PreUserNavigationPageQueryParams {
  /** state: base-64 encoded */
  p: string;
}

export const buildPreUserPageQueryParams = <T>(state: T): PreUserNavigationPageQueryParams => {
  return {
    p: toBase64(JSON.stringify(state)),
  };
};

export const buildPreUserInicioPageQueryParams = <T>(state: T): { t: string } => {
  return {
    t: toBase64(JSON.stringify(state)),
  };
};

export const preUserTelehealthNavigate = <T extends { token: string }, S>(input: {
  path: string;
  queryParam: Omit<T, 'token'>;
  state?: S;
}) => {
  const token = PreUserAuthDataSource.getToken();
  navigate(
    `${input.path}?${QueryString.stringify(
      buildPreUserPageQueryParams<T>({
        token,
        ...input.queryParam,
      } as T)
    )}`,
    {
      state: input?.state,
    }
  );
};

export const preUserInicioNavigate = <S>(state: S): void => {
  const token = PreUserAuthDataSource.getToken();
  navigate(
    `${teleheathPaths.preUserStart.path}?${QueryString.stringify(
      buildPreUserInicioPageQueryParams<{ t: string }>({
        t: token,
      })
    )}`,
    state
  );
};
