export const fisherYatesShuffle = <T>(array: Array<T>): T[] => {
  const temp = array;
  for (let i = temp.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [temp[i], temp[j]] = [temp[j], temp[i]];
  }
  return temp;
};

/**
 * Shuffles the given list according to the provided weights. Items with higher weights
 * are more likely to appear in the shuffled list.
 *
 * @param list The list to shuffle.
 * @param weights The weights to apply to each item in the list.
 * @returns The shuffled list.
 */
export function shuffleWithWeights<T>(list: T[], weights: number[]): T[] {
  // Ensure that the list and weights have the same length
  if (list.length !== weights.length) {
    throw new Error('List and weights must have the same length');
  }

  // Create a new list that duplicates each item in the original list according to its weight
  const weightedList: T[] = [];
  weights.forEach((weight, index) => {
    for (let i = 0; i < weight; i++) {
      weightedList.push(list[index]);
    }
  });

  const shuffledList: T[] = [];

  // Keep track of the items that have already been added to the shuffled list
  const addedItems = new Set<T>();

  // Loop until all items have been shuffled or until no more items can be added
  while (weightedList.length > 0 && shuffledList.length !== list.length) {
    // Choose a random index from the remaining items in the weighted list
    const randomIndex = Math.floor(Math.random() * weightedList.length);
    const itemToAdd = weightedList[randomIndex];

    // If the item has not already been added to the shuffled list, add it
    if (!addedItems.has(itemToAdd)) {
      shuffledList.push(itemToAdd);
      addedItems.add(itemToAdd);
    }

    // Remove the chosen item from the weighted list
    weightedList.splice(randomIndex, 1);
  }

  return shuffledList;
}

export function shuffleWithWeightsAndFallback<T>(list: T[], weights: number[]): T[] {
  try {
    return shuffleWithWeights(list, weights);
  } catch (error) {
    console.error('ERROR: shuffle.ts:51 ~ error:', error);
    return fisherYatesShuffle(list);
  }
}
