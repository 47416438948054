import { FontWeight, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const InlineListWrapper = styled.ul`
  display: inline;
`;

export const InlineListItem = styled.li`
  display: inline;
  margin-right: ${Spacing.Small};
  :before {
    content: '•';
    margin-right: ${Spacing.Small};
    font-weight: ${FontWeight.Bold};
  }
`;
